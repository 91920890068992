// extracted by mini-css-extract-plugin
export var typeLarge = "PageHeader-module--type-large--HMLqR";
export var typeMedium = "PageHeader-module--type-medium--bIFAs";
export var typeBody = "PageHeader-module--type-body--VsKgG";
export var homeIcon = "PageHeader-module--home-icon--hDvpj";
export var padded = "PageHeader-module--padded--NqM58";
export var extraTopPadding = "PageHeader-module--extra-top-padding--MjERJ";
export var title = "PageHeader-module--title--gig8w";
export var yellow = "PageHeader-module--yellow--Q4Lj9";
export var salmon = "PageHeader-module--salmon--vA8JS";
export var peach = "PageHeader-module--peach--rD3GL";
export var purple = "PageHeader-module--purple--Ip7OS";
export var blue = "PageHeader-module--blue--uy6UJ";
export var green = "PageHeader-module--green--R86jB";
export var beige = "PageHeader-module--beige--xhiR1";