import React, { useState, useMemo, createRef, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import BlockContent from '@sanity/block-content-to-react';
import { ContentBlock } from '../../common/ContentBlock';
import { Accordion } from '../../common/Accordion';
import * as styles from './TermsPage.module.scss';
import { PageHeader } from '../../common/PageHeader';
import { TitleBodyList } from '../../common/TitleBodyList';
import { smoothScrollTo } from '../../../utils/common';

const TermsPage = ({ terms, title, common, hash }) => {
  const [expanded, setExpanded] = useState(-1);
  const sections = useMemo(() => {
    return ['conditions', 'privacy', 'accessibility'];
  }, []);

  const sectionsRefs = useMemo(() => {
    return sections.reduce((acc, value) => {
      acc[value] = createRef();
      return acc;
    }, {});
  }, [sections]);

  useEffect(() => {
    if (
      hash &&
      sectionsRefs[hash.substring(1)]?.current &&
      hash.substring(1).trim() // Changed this to satisfy linter, hopefully still works
    ) {
      setExpanded(hash.substring(1));

      return smoothScrollTo(sectionsRefs[hash.substring(1)].current);
    }
  }, [hash]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = (e) => {
    // access to e.target here
    window.location.href =
      window.location.href.split('#')[0] + '#' + e.currentTarget.dataset.id;
  };

  return (
    <>
      <PageHeader title={title} padded color={'yellow'} />

      <ContentBlock>
        <div className={styles.accordion}>
          {/* Terms */}
          <Accordion
            i={sections[0]}
            onClick={handleClick}
            key={sections[0]}
            ref={sectionsRefs[sections[0]]}
            expanded={expanded}
            setExpanded={setExpanded}
            title={terms.termsHeader}
            color={'yellow'}>
            <div className={` ${styles.padding}`}>
              <BlockContent blocks={terms?._rawTermsBody} />
            </div>
          </Accordion>

          {/* Privacy */}
          <Accordion
            i={sections[1]}
            onClick={handleClick}
            key={sections[1]}
            ref={sectionsRefs[sections[1]]}
            expanded={expanded}
            setExpanded={setExpanded}
            title={terms.privacyHeader}
            color={'yellow'}>
            <div className={`${styles.padding}`}>
              <div>
                <BlockContent blocks={terms?._rawPrivacyBody} />
              </div>
            </div>
          </Accordion>
        </div>
      </ContentBlock>
    </>
  );
};

export { TermsPage };
