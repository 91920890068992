import React from "react";
import * as styles from "./ContentBlock.module.scss";
import classNames from "classnames";

const ContentBlock = ({ children, padded, extraPadded, className }) => {
  const cls = classNames(
    { [styles.padded]: padded },
    { [styles.extraPadded]: extraPadded },
    styles.contentBlock,
    className
  );
  return <div className={cls}>{children}</div>;
};

export { ContentBlock };
