import * as React from "react";
import { motion } from "framer-motion";
import { springOne, springOneDelay } from "../../../../utils/common";

export const Content = ({ children }) => (
  <motion.div
    variants={{ collapsed: { x: "-100%" }, open: { x: 0 } }}
    transition={springOneDelay}
    // transition={{ duration: 0.8 }}
  >
    {children}
  </motion.div>
);
