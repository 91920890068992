import * as React from 'react';
import { graphql } from 'gatsby';
import localize from '../components/localize';

import { TermsPage } from '../components/pageLayout/TermsPage';

export const query = graphql`
  query TermsPageQuery {
    terms: sanitySingletonAdmin {
      pageHeader {
        _type
        en
        sc
        tc
      }
      termsHeader {
        _type
        en
        sc
        tc
      }
      privacyHeader {
        _type
        en
        sc
        tc
      }
      accessibilityHeader {
        _type
        en
        sc
        tc
      }
      _rawTermsBody(resolveReferences: { maxDepth: 10 })
      _rawPrivacyBody(resolveReferences: { maxDepth: 10 })
      _rawAccessibilityBody(resolveReferences: { maxDepth: 10 })
    }
  }
`;

const Terms = ({ data, location }) => {
  const terms = data?.terms;
  return (
    <main>
      <title>{terms?.pageHeader}</title>
      <TermsPage
        terms={terms}
        title={terms?.pageHeader}
        hash={location?.hash}
      />
    </main>
  );
};

export default localize(Terms);
