// extracted by mini-css-extract-plugin
export var typeLarge = "Accordion-module--type-large--K6g3k";
export var typeMedium = "Accordion-module--type-medium--sEpaZ";
export var typeBody = "Accordion-module--type-body--QWhfE";
export var homeIcon = "Accordion-module--home-icon--oJc4z";
export var contentPlaceholder = "Accordion-module--content-placeholder--o+bMk";
export var downArrowWrapper = "Accordion-module--down-arrow-wrapper--kq843";
export var downArrow = "Accordion-module--down-arrow--zbF85";
export var container = "Accordion-module--container--JlnEO";
export var header = "Accordion-module--header--Bujbb";
export var accordionSection = "Accordion-module--accordion-section--S377r";
export var word = "Accordion-module--word--0PfTh";
export var paragraph = "Accordion-module--paragraph--ytbby";
export var yellow = "Accordion-module--yellow--qAyrL";
export var open = "Accordion-module--open--Aug0d";
export var peach = "Accordion-module--peach--wocYY";
export var overlap = "Accordion-module--overlap--IUl9I";