import * as React from "react";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Content } from "./Content";
import * as styles from "./Accordion.module.scss";
import { Icon } from "../Icon";
import { springOne, springTwo } from "../../../utils/common";
import classNames from "classnames";

// see here for framer example:
// https://codesandbox.io/s/framer-motion-accordion-forked-jc6jbk?file=/src/index.tsx
export const Accordion = React.forwardRef(
  (
    {
      i,
      expanded,
      setExpanded,
      children,
      title,
      color,
      onClick,
      overlap = false,
    },
    ref
  ) => {
    const isOpen = i === expanded;

    const headerStyles = classNames(styles.header, styles[color], {
      [styles.open]: isOpen,
      [styles.overlap]: overlap,
    });

    const sectionStyles = classNames(styles.accordionSection, styles[color], {
      [styles.open]: isOpen,
      [styles.overlap]: overlap,
    });

    // By using `AnimatePresence` to mount and unmount the contents, we can animate
    // them in and out while also only rendering the contents of open accordions
    return (
      <div
        className={styles.container}
        ref={ref}
        data-id={i}
        // id={i}
        onClick={onClick}
      >
        <motion.header
          className={headerStyles}
          initial={false}
          // animate={{ backgroundColor: isOpen ? "#FF0088" : "#0055FF" }}
          onClick={() => setExpanded(isOpen ? false : i)}
        >
          <h2>{title}</h2>
          <motion.div
            className={styles.downArrowWrapper}
            initial={false}
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={springOne}
          >
            <Icon name="arrow-down" className={styles.downArrow} />
          </motion.div>
        </motion.header>
        <AnimatePresence initial={false}>
          {isOpen && (
            <motion.section
              className={sectionStyles}
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={{
                open: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              // transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              transition={springOne}
            >
              <Content>{children}</Content>
            </motion.section>
          )}
        </AnimatePresence>
      </div>
    );
  }
);

export const Example = () => {
  // This approach is if you only want max one section open at a time. If you want multiple
  // sections to potentially be open simultaneously, they can all be given their own `useState`.
  const [expanded, setExpanded] = useState(0);

  return accordionIds.map((i) => (
    <Accordion i={i} expanded={expanded} setExpanded={setExpanded}>
      content here
    </Accordion>
  ));
};

const accordionIds = [0, 1, 2, 3];
