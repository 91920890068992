import React from "react";
import * as styles from "./PageHeader.module.scss";
import classNames from "classnames";

const PageHeader = ({ title, padded, extraTopPadding, className, color }) => {
  const cls = classNames(
    styles.title,
    { [styles.extraTopPadding]: extraTopPadding },
    { [styles.padded]: padded },
    styles[color],
    className
  );
  return (
    <div className={cls}>
      <h1 className="type-medium">{title}</h1>
    </div>
  );
};

export { PageHeader };
